<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Time Amount: <small>{{ timeAmount.name }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label for="time_amount_title">Time Amount Title</label>
      <input type="text" class="form-control" id="time_amount_title" v-model="timeAmount.name" placeholder="Name">
    </div>

    <div class="form-group text-left">
      <label for="time_amount">Time Amount</label>
      <input type="number" step="0.1" class="form-control" id="time_amount" v-model="timeAmount.amount">
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'TimeAmountIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="createTimeAmount" type="button" class="btn btn-success">Create Time Amount</button>
      </div>
    </div>

    <br>

  </div>
</template>

<script>
import { TimeAmountService } from '@/common/services/time_amount.service';
import DangerAlert from '@/common/components/danger-alert.vue';

export default {
  name: 'LanguageNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      timeAmount: {
        name: '',
        amount: 0.0,
      },
      errors: [],
    };
  },
  methods: {
    createTimeAmount() {
      TimeAmountService.create(this.timeAmount)
        .then(({ data }) => {
          this.$router.push({ name: 'TimeAmountShow', params: { id: data.id } }, () => {
            this.$notify({
              title: 'Created Time Amount',
              text: `Successfully created ${data.name}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/time_amounts";
</style>
